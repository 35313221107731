import ReactDOM from 'react-dom';
import { IMicroFrontendInstance } from '@hmg-aka-lu/cockpit-frontend/dist-types/exports';

// import {
//   createBrowserHistory,
//   History,
// } from 'history';
import './i18n';

import './theme/FontFace.css';
import LightHouseThemeProvider from './theme/LightHouseThemeProvider';
import LUSettings from './components/LUSettings';
import mergedConfig from './config';
declare global {
  interface Window {
    luSettings: IMicroFrontendInstance;
  }
}

const microFrontendInstance: IMicroFrontendInstance = (containerId = 'root', history: any, microfrontendMeta) => ({
  render: () => {
    ReactDOM.render(
      <LightHouseThemeProvider>
        <LUSettings getJWT={microfrontendMeta.getJWT} />
      </LightHouseThemeProvider>,
      document.getElementById(containerId)
    );
  },
});

window.luSettings = microFrontendInstance;

if (mergedConfig.STANDALONE) {
  const microFrontend = microFrontendInstance(
    'root',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    undefined,
    {
      getJWT: async () => {
        return '';
      },
      hasCockpitAccess: () => true,
      hasRequiredGroup: () => true,
      authenticatedContext: {},
      relativeUrl: '12312',
    }
  );
  microFrontend.render();
}
